import React from 'react'
import BaseComponent from './components/BaseComponent'
import { withRouter, Switch, Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Header from './components/Header'
import ErrorPage from './components/ErrorPage'
import StartPage from './components/StartPage'
import SinglePage from './components/SinglePage'
import CompanyPage from './components/CompanyPage'
import qs from 'query-string'

class MainView extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { cuid: '', screen : { width: window.innerWidth, height: window.innerHeight }, nav: window.innerWidth > 1020 ? true : false, notifications: [], paramsObjects: [], fromApp: this.props.app }
    this._bind('toggleNav', 'hideNav', 'addNotify', 'closeNotify')
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
    window.addEventListener("NOTIFY", (data) => { this.addNotify(data) })

    const CUID = qs.parse(this.props.location.search)?.cuid
    this.setState({cuid: CUID})

    console.log(this.props.lang, CUID)
    

    if (this.props.confirm) {
      setTimeout(() => {
        this.props.history.push("/b2b/single"+window.location.search)
      }, 500)
    }
  }

  addNotify(e) {
    let stamp = new Date()
    stamp = stamp.getTime()
    let temp = this.state.notifications
    let item = { type: e.detail.type, content: e.detail.content, stamp: stamp }
    temp.push(item)
    this.setState({ notifications: temp })
    setTimeout(() => {
      this.closeNotify(stamp)
    }, 10000)
  }

  closeNotify(stamp) {
    let index = 999
    let temp = this.state.notifications
    for (var i = 0; i < this.state.notifications.length; i++) {
      if(this.state.notifications[i].stamp === stamp) {
        index = i
      }
    }
    temp.splice(index, 1);
    this.setState({ notifications: temp })
  }

  scrollTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      setTimeout(this.scrollTop, 250)
    }
  }

  handleResize(e) {
    this.setState({ screen : { width: window.innerWidth, height: window.innerHeight }})
  }

  toggleNav() {
    if (this.state.nav) {
      this.setState({ nav: false })
    } else {
      this.setState({ nav: true })
    }
  }

  hideNav() {
    if (this.state.screen.width < 1020) {
      this.setState({ nav: false })
    }
  }

  render() {

    let notifications = this.state.notifications.map((item, i) => {
      return (
        <CSSTransition key={ item.stamp } classNames="notification" timeout={{ enter: 500, exit: 500 }} >
          <div className={ "notification " + item.type } style={{ transform: "translateY("+(i*50)+"px)" }} onClick={ this.closeNotify.bind(null, item.stamp) }>
            <div className="close"></div>
            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
          </div>
        </CSSTransition>
      )
    })

    let langPrefix = ""

    return (
      <div className="app-inner">
        <Header data={ this.props.data } lang={ this.props.lang }/>
        <TransitionGroup className={ this.state.nav ? "page-holder open" : "page-holder" }>
          <CSSTransition key={ this.props.location.pathname } classNames="page" timeout={{ enter: 500, exit: 250 }} >
            <Switch location={ this.props.location }>
              <Route  path={ "/" } exact render={(props) => (<StartPage confirm={ this.props.confirm } id={ this.props.id } app={ this.state.fromApp } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } {...props}/>)}/>
              <Route  path={ "/b2b" } exact render={(props) => (<StartPage confirm={ this.props.confirm } id={ this.props.id } app={ this.state.fromApp } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } {...props}/>)}/>
              <Route  path={ "/b2b/single" } exact render={(props) => (<SinglePage cuid={this.state.cuid} confirm={ this.props.confirm } id={ this.props.id } app={ this.state.fromApp } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } {...props}/>)}/>
              <Route  path={ "/b2b/company" } exact render={(props) => (<CompanyPage confirm={ this.props.confirm } id={ this.props.id } app={ this.state.fromApp } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } {...props}/>)}/>
              <Route  path={ "/error/:error?" } render={(props) => (<ErrorPage data={ this.props.data } {...props}/>)} />
              <Route render={(props) => (<ErrorPage data={ this.props.data } {...props}/>)} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <TransitionGroup className="notifications">
          { notifications }
        </TransitionGroup>
      </div>
    )
  }
}

export default withRouter(MainView)